<template>
  <div class="paperReport">
    <div class="top">
      <div class="title-box">
        <div class="goback" @click="back">
          <i class="el-icon-arrow-left"></i>返回
        </div>
        <div class="title">
          <span>
            <div :class="['sub-tag', topData.type == 99 ? 'other' : 'general']">
              {{ getExamType(topData.type) }}
            </div>

            <el-tooltip
              class="item"
              effect="dark"
              :content="topData.name"
              placement="top"
            >
              <span>{{ topData.name }}</span>
            </el-tooltip>
          </span>
        </div>

        <div>
          <span v-if="level == 1">小{{ topData.year }}级</span>
          <span v-if="level == 2">初{{ topData.year }}级</span>
          <span v-if="level == 3">高{{ topData.year }}级</span>
          <span class="examDate"> {{ topData.examDate }}</span>
        </div>
      </div>
      <div class="empty"></div>
      <div class="sub-title">试卷讲评</div>
      <div class="download">
        <reportDownLoad />
        <!-- <el-button type="warning">讲评PPT下载</el-button> -->
      </div>
    </div>
    <div v-loading="loading" class="answer">
      <div class="answer-title">
        <div class="title">题目应答情况</div>
        <div class="filters">
          <el-select
            v-model="search.subjectId"
            placeholder="科目"
            style="width: 80px; margin-right: 8px"
            @change="subjectChange"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="search.classNum"
            placeholder="班级"
            style="width: 80px"
            @change="selectChange"
          >
            <el-option
              v-for="item in tabs"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="content">
        <div class="chart">
          <div
            v-if="questions.length"
            ref="barChart"
            style="width: 100%; height: 216px"
          ></div>
          <no-data v-else></no-data>
        </div>
        <div class="data-box mt20">
          <div class="box-item bg1" style="margin-bottom: 18px">
            <div class="num">{{ goodQuestionOrders || "暂无" }}</div>
            <div class="text">应答情况相对较好的题目</div>
          </div>
          <div class="box-item bg2">
            <div class="num">
              {{ badQuestionOrders || "暂无" }}
              <template v-if="badQuestionOrdersArr.length > 5">
                <el-button type="text" @click="showDetail">{{
                  extendBad ? "收起" : "展开"
                }}</el-button>
              </template>
            </div>

            <div class="text">建议重点讲评的题目</div>
          </div>
        </div>
      </div>
    </div>
    <analysis
      v-if="search.subjectId"
      :key="search.classNum + search.subjectId"
      :params="search"
      :is-school-display-report-union-average-score="
        isSchoolDisplayReportUnionAverageScore
      "
      :subject-id="search.subjectId"
    />
  </div>
</template>
<script>
import { examTypeOptions } from "@/core/util/constdata";

import {
  getstatclassoption,
  getstatsubjectoption,
} from "@/core/api/academic/common";
import reportDownLoad from "./components/reportDownLoad";
import analysis from "./components/analysis.vue";
import { getprreportquestionanswer } from "@/core/api/academic/paperReport";
import { Uniongetprreportquestionanswer } from "@/core/api/academic/unionReport";
import * as echarts from "echarts";
export default {
  name: "PaperReport",
  components: { analysis, reportDownLoad },
  data() {
    return {
      tabs: [],
      search: {
        classNum: "",
      },
      options: [],
      statId: this.$route.query.id,
      topData: { ...this.$route.query },
      level: this.$route.query.level,
      badQuestionOrders: "",
      goodQuestionOrders: "",
      loading: false,
      questions: "",
      maxWidth: 0,
      examType: this.$route.query.examType,
      subjectList: [],
      isSchoolDisplayReportUnionAverageScore: null,
      badQuestionOrdersArr: [],
      extendBad: false,
    };
  },
  mounted() {
    this.getConditionList();
  },
  methods: {
    async getprreportquestionanswerData() {
      this.loading = true;
      let res;
      if (this.examType == 1) {
        res = await Uniongetprreportquestionanswer({
          id: this.statId,
          viewSchoolId: this.$route.query.schoolId,
          ...this.search,
        });
      } else {
        res = await getprreportquestionanswer({
          id: this.statId,
          ...this.search,
        });
      }

      this.loading = false;

      this.isSchoolDisplayReportUnionAverageScore =
        res.data.data.isSchoolDisplayReportUnionAverageScore;
      this.goodQuestionOrders = res.data.data.goodQuestionOrders
        .toString()
        .replace(/,/g, "、");
      this.badQuestionOrdersArr = res.data.data.badQuestionOrders;
      this.questions = res.data.data.questions;
      this.formatterBad();
      this.$nextTick(() => {
        this.initChart(res.data.data.questions);
      });
    },
    formatterBad() {
      let data = this.extendBad
        ? this.badQuestionOrdersArr
        : this.badQuestionOrdersArr.slice(0, 5);
      this.badQuestionOrders = data.join(",").replace(/,/g, "、");
    },
    showDetail() {
      this.extendBad = !this.extendBad;
      this.formatterBad();
    },
    initChart(data) {
      let series = [];
      if (this.examType != 1) {
        series = [
          {
            name: "得分率",
            type: "bar",
            barMaxWidth: 40,
            color: "#7AB5EF",
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "inherit",
                    fontSize: 9,
                  },
                },
              },
            },
            data: data.map((item) => item.scoreRate),
          },
          {
            name: "年级",
            type: "line",
            smooth: true,
            color: "#FF9776",
            itemStyle: {
              normal: {
                label: {
                  show: false,
                  position: "top",
                  textStyle: {
                    color: "#FF9776",
                  },
                },
              },
            },
            data: data.map((item) => item.schoolScoreRate),
          },
        ];
      } else {
        series = [
          {
            name: "班级",
            type: "bar",
            barMaxWidth: 40,
            color: "#7AB5EF",
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: "top",
                  textStyle: {
                    color: "inherit",
                    fontSize: 9,
                  },
                },
              },
            },
            data: data.map((item) => item.scoreRate),
          },
          {
            name: this.$route.query.examType == 1 ? "学校" : "年级",
            type: "line",
            smooth: true,
            color: "#FF9776",
            data: data.map((item) => item.schoolScoreRate),
          },
        ];
        if (this.isSchoolDisplayReportUnionAverageScore == 1) {
          series.push({
            name: "整体",
            type: "line",
            smooth: true,
            color: "#F57574",
            data: data.map((item) => item.unionScoreRate),
          });
        }
      }
      let options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#000",
            },
          },
        },
        legend: { itemGap: 30 },
        grid: {
          bottom: data.length > 12 ? "20%" : "5%",
          containLabel: true,
        },
        dataZoom: [
          {
            show: data.length > 12,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: data.length - (data.length - 12),
            xAxisIndex: [0],
            bottom: "5%",
            left: "center",
          },
        ],
        color: ["#7AB5EF", "#FF9776"],
        xAxis: [
          {
            type: "category",
            data: data.map((item) => item.questionOrder),
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
              formatter: (val) => {
                let arr = val.split("");
                let index = 0;
                let newArray = [];
                while (index < arr.length) {
                  let data = arr.slice(index, (index += 5));
                  newArray.push(data.join(""));
                }
                let str = newArray.reduce((str, item) => str + "\n" + item, "");
                return str;
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
            },
          },
        ],
        series: [...series],
      };
      this.chartObj = echarts.init(this.$refs.barChart, null, {
        devicePixelRatio: 2.5,
      });
      this.chartObj.setOption(options, true);
      let that = this;
      setTimeout(() => {
        window.onresize = function () {
          that.chartObj.resize();
        };
      }, 200);
    },
    getClassList(subjectId) {
      return getstatclassoption({
        id: this.statId,
        subjectId: subjectId,
        requestPage: 5,
        viewSchoolId: this.$route.query.schoolId,
      });
    },
    getSubjectList() {
      return getstatsubjectoption({
        id: this.statId,
        hasTotal: 0,
        requestPage: 5,
        classNum: this.search.classNum,
        viewSchoolId: this.$route.query.schoolId,
      });
    },
    async getConditionList() {
      const subjectRes = await this.getSubjectList();
      const data = subjectRes.data.data.filter((item) => {
        if (item.type !== 2 && item.type !== 3) {
          return item;
        }
      });
      this.subjectList = subjectRes;
      this.options = data;
      const classRes = await this.getClassList(subjectRes.data.data[0].id);
      this.tabs = classRes.data.data;
      this.search = {
        classNum: classRes.data.data[0].name,
        subjectId: this.options[0].id,
      };
      this.$store.commit("setLevelId", this.$route.query.level);
      this.$store.commit("setSubjectId", this.search.subjectId);
      this.subjectName = this.options.length > 0 ? this.options[0].name : "";
      this.getprreportquestionanswerData();
    },
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value == type);
        return data.label;
      }
    },
    async subjectChange() {
      this.$store.commit("setLevelId", this.$route.query.level);
      this.$store.commit("setSubjectId", this.search.subjectId);
      const classRes = await this.getClassList(this.search.subjectId);
      this.tabs = classRes.data.data;
      this.search.classNum = classRes.data.data[0].name;

      this.getprreportquestionanswerData();
    },

    selectChange() {
      this.$store.commit("setLevelId", this.$route.query.level);
      this.$store.commit("setSubjectId", this.search.subjectId);
      this.subjectName = this.subjectList.data.data[0].name;
      this.getprreportquestionanswerData();
    },
    back() {
      this.$router.go(-1);
    },
    handleChange() {},
  },
};
</script>
<style lang="scss" scoped>
.paperReport {
  width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1300px) {
    width: auto;
  }

  .subjects {
    margin-bottom: 24px;
    text-align: center;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 16px 18px;
    background-color: #fff;
    position: relative;
    margin-top: 18px;

    .title-box {
      display: flex;
      align-items: center;
      margin: 0;
      justify-content: flex-start;
      position: absolute;
      left: 18px;
      top: 50%;
      transform: translateY(-50%);
      width: 530px;

      .goback {
        color: #101011;
        font-size: 14px;
        margin-right: 18px;
        cursor: pointer;
        width: fit-content;
        display: flex;
        align-items: center;

        justify-content: flex-start;
        min-width: fit-content;
      }

      .title {
        font-size: 14px;
        margin-right: 8px;
        padding: 0;
        max-width: 280px;

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          align-items: center;
        }

        .sub-tag {
          margin-right: 8px;
        }
      }
    }

    .sub-title {
      color: #3f87f4;
      font-size: 16px;
      font-weight: bold;
      flex: 1;
      text-align: center;
    }

    .download {
      flex: 1;
      text-align: right;
    }

    .empty {
      flex: 1;
    }
  }
}

.answer {
  margin: 20px auto;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 5%);
  border-radius: 10px;
  line-height: 1;
  background-color: #fff;

  .answer-title {
    display: flex;
    box-sizing: border-box;
    padding: 20px;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 14px;
      color: #101011;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 20px;
    border-top: 1px solid #f3f4f4;

    .chart {
      margin-right: 18px;
      flex: 1;
    }

    .data-box {
      .box-item {
        width: 400px;
        height: 99px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .num {
          font-size: 20px;
          line-height: 28px;
          text-align: center;
          cursor: default;
        }

        .text {
          padding-top: 7px;
          font-size: 12px;
          text-align: center;
          color: #666;
        }
      }

      .light {
        margin-left: 24px;

        .num {
          color: #ff6833;
        }
      }
    }
  }
}

::v-deep .el-tabs__nav-wrap::after {
  display: none;
}

::v-deep .el-tabs__active-bar {
  display: none;
}

::v-deep .el-tabs__item {
  padding: 0 24px !important;
  height: 32px;
  line-height: 32px;
  margin: 0 18px;
  box-sizing: border-box;
}

::v-deep .el-tabs {
  .is-active {
    border: 1px solid #3f87f4;
    border-radius: 16px;
    background-color: #f2faff;
  }
}

::v-deep .el-icon-arrow-left {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}

::v-deep .el-icon-arrow-right {
  &::before {
    font-size: 24px;
    line-height: 24px;
  }
}

.sub-tag {
  box-sizing: border-box;
  padding: 0 8px;
  font-size: 12px;
  border: 1px solid;
  height: 16px;
  line-height: 14px;
  width: fit-content;
}

.other {
  color: #3f87f4;
  border-color: #3f87f4;
}

.general {
  color: #ffaf6a;
  border-color: #ffaf6a;
}

.bg1 {
  background: #f3f9ff;

  .num {
    color: #3f87f4;
  }
}

.bg2 {
  background: #fff6f3;

  .num {
    color: #ff7b4f;
  }
}
</style>
