import request from "@/core/services/axios";
export function getprreportquestionanswer(params) {
  // 试卷讲评-题目应答情况
  return request({
    url: `/report/statpaperreviewreport/getprreportquestionanswer`,
    method: `get`,
    params: params,
  });
}
export function getprreportanalysis(params) {
  // 试卷讲评-讲评分析
  return request({
    url: `/report/statpaperreviewreport/getprreportanalysis/v2`,
    method: `get`,
    params: params,
  });
}
export function getAnswer(id) {
  // 试卷讲评-获取答案解析
  return request({
    url: `/resource/examquestion/answer/paper`,
    method: `get`,
    params: {
      id: id,
    },
  });
}
export function examquestionOpt(data) {
  // 试卷讲评-获取同类题
  return request({
    url: `/resource/examquestion/opt`,
    method: `get`,
    params: data,
  });
}
export function exportTypicalAnswerSheet(data) {
  // 导出典型学生答题卡（导出典型试卷）
  return request({
    url: `/report/statoneexamexport/exportTypicalAnswerSheet`,
    method: `post`,
    data: data,
  });
}
export function downloadunionclassteachreport(data) {
  // 导出试卷讲评报告
  if (data.viewSchoolId) {
    return request({
      url: `/report/stat/exportunionclassteachreport`,
      method: `get`,
      params: data,
      headers: {
        "VIEW-SCHOOL-ID": data.viewSchoolId,
      },
    });
  }

  return request({
    url: `/report/stat/downloadunionclassteachreport`,
    method: `get`,
    params: data,
  });
}
