<template>
  <div class="reportDownLoad">
    <el-button type="primary" @click="openModal"> 讲评报告下载 </el-button>
    <el-dialog
      title="导出班级讲评报告"
      :visible.sync="downloadVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div v-loading="loading">
        <div class="form-item">
          科目：
          <el-select
            v-model="exportForm.subjectId"
            placeholder="请选择"
            clearable
            @change="subjectChange"
          >
            <el-option
              v-for="item in subjectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          请选择班级：<el-button type="text" @click="checkAll">全选</el-button>
          <el-button type="text" @click="reverse">反选</el-button>
          <el-button type="text" @click="cancle">取消</el-button>
        </div>
        <el-checkbox-group v-model="exportForm.classNums">
          <el-checkbox
            v-for="(item, index) in classList"
            :key="index"
            :label="item.name"
          >
            {{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="downloadVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loadingbtn" @click="exportExcel"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getstatsubjectoption,
  getstatclassoption,
} from "@/core/api/academic/common";
import { downloadunionclassteachreport } from "@/core/api/academic/paperReport";
import { fileNameByUrl } from "@/core/util/util";
export default {
  data() {
    return {
      downloadVisible: false,
      exportForm: {
        classNums: [],
      },
      loading: false,
      loadingbtn: false,
      classList: [],
      subjectList: [],
    };
  },
  methods: {
    subjectChange() {
      this.getstatclassoption();
    },
    openModal() {
      this.downloadVisible = true;
      this.loading = true;
      this.getstatsubjectoption();
    },
    async getstatsubjectoption() {
      const res = await getstatsubjectoption({
        hasTotal: 0,
        id: this.$route.query.id,
        requestPage: 5,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.subjectList = [...res.data.data];

      this.getstatclassoption();
    },
    async getstatclassoption() {
      const res = await getstatclassoption({
        id: this.$route.query.id,
        subjectId: this.exportForm.subjectId,
        requestPage: 5,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.classList = res.data.data;
      this.loading = false;
    },
    cancle() {
      this.exportForm.classNums = [];
    },
    reverse() {
      let arr = this.classList.map((item) => item.name);
      this.exportForm.classNums = arr.filter(
        (item) => !this.exportForm.classNums.includes(item)
      );
    },
    checkAll() {
      this.exportForm.classNums = this.classList.map((item) => item.name);
    },
    exportExcel() {
      if (!this.exportForm.subjectId) {
        this.$message({
          message: "请选择科目",
          type: "warning",
          showClose: true,
        });
      } else if (!this.exportForm.classNums.length) {
        this.$message({
          message: "请选择班级",
          type: "warning",
          showClose: true,
        });
      } else {
        this.loadingbtn = true;
        const shcoolId = JSON.parse(
          localStorage.getItem("jzjx-school_id") || "{}"
        ).content;
        downloadunionclassteachreport({
          examId: this.$route.query.examId,
          subjectId: this.exportForm.subjectId,
          classNums: this.exportForm.classNums.join(","),
          viewSchoolId: this.$route.query.schoolId || shcoolId,
          id: this.$route.query.id,
        })
          .then((res) => {
            if (!res.data.data.url) {
              this.loadingbtn = false;
              this.$message({
                message: "报告不存在",
                type: "warning",
                showClose: true,
              });
            } else {
              this.loadingbtn = false;
              this.downloadVisible = false;
              fileNameByUrl(res.data.data.url, res.data.data.name);
            }
          })
          .catch(() => {
            this.loadingbtn = false;
          });
      }
    },
    handleClose() {
      this.downloadVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
::v-deep .el-dialog__header {
  text-align: left;
}
::v-deep .el-checkbox-group {
  text-align: left;
  // display: grid;
  // grid-template-columns: repeat(5, 1fr);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
