<template>
  <div id="analysis" v-loading="loading" class="analysis">
    <div class="questions-box">
      <div v-if="open" class="questions-board">
        <div class="header">
          <div>答题情况</div>
          <el-select
            v-model="type"
            size="mini"
            placeholder="请选择"
            style="width: 110px"
            @change="selectChange"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="close" @click="closeBoard">
            <i class="el-icon-arrow-left"></i>收起
          </div>
        </div>
        <div class="content">
          <div :class="['btn-box']">
            <div
              v-for="(item, index) in questionsNumList"
              :key="index"
              :class="[getBorderColorClass(item), getBtnLong(item)]"
              :style="{
                backgroundColor:
                  subindex === index ? getBackgroundColor(item) : '#fff',
                borderColor: subindex === index ? getBackgroundColor(item) : '',
                color: subindex === index ? '#fff' : getColor(item),
              }"
              @click="targetTo(item, index)"
            >
              {{ item.questionOrder }}
            </div>
          </div>
          <div class="colorList">
            <div
              v-for="(item, index) in customColors"
              :key="index"
              class="colorList-item"
              :style="{ color: item.hidden ? '#AAAAAF' : '#101011' }"
            >
              <div
                class="block"
                :style="{
                  backgroundColor: item.hidden ? '#CFCFD1' : item.color,
                }"
                @click="blockClick(item)"
              ></div>
              {{ index === 0 ? "0" : customColors[index - 1].percentage }}% ≤
              班级得分率 <span v-if="index < 4">＜</span
              ><span v-else> ≤ </span> {{ item.percentage }}%
            </div>
          </div>
          <div class="tip">*点击色块可以筛选对应得分率的题目</div>
        </div>
      </div>
      <div v-else class="questions-board-close" @click="openboard">
        试 题 面 板
      </div>
      <div
        v-if="questionList.length"
        class="questions"
        :style="{ width: `calc(100% - ${questionsWidth}px)` }"
      >
        <div
          v-for="(item, index) in questionList"
          :id="'question' + index"
          :key="index"
          class="questions-item"
        >
          <div class="header">
            <div class="left">
              <span
                v-if="item.questionOrder"
                :class="['questionNum']"
                :style="{
                  background: getColor(
                    item.questions[item.subIndex],
                    'questionList',
                    index,
                    item
                  ),
                }"
                >{{ item.questionOrder }}</span
              >
              <div v-else class="material">
                <img src="@/assets/imgs/material.png" alt="" />
              </div>
              <span class="questionType"
                >题型：{{ item.isObjective === 0 ? "主观题" : "客观题" }}</span
              >
            </div>
            <div class="right">
              <template
                v-if="item.questions.length && item.question && showBtn"
              >
                <el-button
                  v-if="isInplanMaterial(item)"
                  type="text"
                  @click="setExampaperplanquestion(item)"
                >
                  加入试题篮
                  <i
                    class="el-icon-circle-plus-outline"
                    style="margin-left: 8px"
                  >
                  </i>
                </el-button>
                <el-button
                  v-else
                  type="text"
                  @click="exampaperplanquestionDel(item)"
                >
                  移除试题篮<i
                    class="el-icon-remove-outline"
                    style="margin-left: 8px"
                  ></i>
                </el-button>
              </template>
              <!-- <template v-else>
                <span class="answer">正确答案：{{ item.rightAnswer }}</span>
                <span class="fullscore">满分：{{ item.score }}</span>
              </template> -->
            </div>
          </div>
          <div
            v-if="item.question || item.knowledgeNames"
            class="content"
            :style="{
              padding: item.question || item.knowledgeNames ? '18px' : '18px 0',
            }"
          >
            <div
              :class="[
                isOver(item.question) === 'block' ? 'over' : '',
                item.open ? 'content-question' : 'content-question-close',
              ]"
            >
              <div class="item-content" v-html="item.question"></div>
              <div
                v-if="!item.open"
                class="open-btn"
                :style="{ display: isOver(item.question), fontSize: '14px' }"
                @click="extendItem(item)"
              >
                <i class="el-icon-caret-bottom"></i>展开
              </div>
            </div>
            <div
              v-if="item.open"
              class="close-btn"
              :style="{ display: isOver(item.question), fontSize: '14px' }"
              @click="stowItem(item)"
            >
              <i class="el-icon-caret-top"></i>收起
            </div>
          </div>
          <!-- 材料题小题 start -->
          <div v-if="!item.questionOrder" class="subQuestions">
            <div class="subQuestions-tabs">
              <div
                v-for="(v, n) in item.questions"
                :key="n"
                :class="[getBorderColorClass(v), 'subQuestions-tab']"
                :style="{
                  backgroundColor:
                    item.subIndex === n ? getBackgroundColor(v) : '#fff',
                  borderColor: item.subIndex === n ? getBackgroundColor(v) : '',
                  color: item.subIndex === n ? '#fff' : getColor(v),
                }"
                @click="handleSubClick(item, index, v, n)"
              >
                {{ v.questionOrder }}
              </div>
            </div>
          </div>
          <!-- 材料题小题 end -->
          <div
            v-if="
              item.questions.length &&
              item.questions[item.subIndex].knowledgeNames.length &&
              knowledgeShow
            "
            class="knowledgeNames"
          >
            <div class="item-label">【知识点】：</div>
            <div class="knowledgeNames-content">
              <div
                v-for="(it, ind) in item.questions[item.subIndex]
                  .knowledgeNames"
                :key="ind"
                class="knowledgeNames-item"
              >
                {{ it }}
              </div>
            </div>
          </div>
          <div class="footer">
            <div class="footer-content">
              <div class="scoreStat">
                <span v-if="item.questions.length" style="margin-right: 20px"
                  >班级均分/得分率：{{
                    item.questions[item.subIndex].averageScore
                  }}分/{{ item.questions[item.subIndex].scoreRate }}%</span
                >
                <span v-if="item.questions.length" style="margin-right: 20px"
                  >{{
                    $route.query.examType == 1
                      ? "学校均分/得分率："
                      : "年级均分/得分率："
                  }}{{ item.questions[item.subIndex].schoolAverageScore }}分/{{
                    item.questions[item.subIndex].schoolScoreRate
                  }}%</span
                >
                <span
                  v-if="
                    item.questions.length &&
                    $route.query.examType == 1 &&
                    isSchoolDisplayReportUnionAverageScore == 1
                  "
                >
                  整体均分/得分率：{{
                    item.questions[item.subIndex].unionAverageScore
                  }}分/{{ item.questions[item.subIndex].unionScoreRate }}%</span
                >
              </div>
              <div class="btns">
                <el-button
                  v-if="
                    item.questions.length &&
                    item.question &&
                    item.questions[item.subIndex].questionIds[0]
                  "
                  type="primary"
                  plain
                  szie="mini"
                  @click="showAnswer(item)"
                  >答案解析</el-button
                >
                <!-- <el-button
                  v-if="item.questions.length"
                  szie="mini"
                  type="success"
                  plain
                  >回归教材</el-button
                > -->
                <el-button
                  v-if="
                    item.questions.length &&
                    item.questions[item.subIndex].questionOrder &&
                    item.question &&
                    item.questions[item.subIndex].examPaperId &&
                    showBtn &&
                    item.questions[item.subIndex].knowledgeNames.length &&
                    knowledgeShow
                  "
                  type="danger"
                  plain
                  szie="mini"
                  @click="showOpt(item)"
                  >显示同类题</el-button
                >
                <el-button
                  type="warning"
                  plain
                  szie="mini"
                  @click="showStat(item)"
                  >答案统计</el-button
                >
              </div>
            </div>
          </div>
          <div
            v-if="item.showAnswer"
            v-loading="item.loading"
            class="showAnswer"
          >
            <div
              v-if="item.answers && item.answers.questionType == 1"
              class="tips"
            >
              当前试题没有答案解析，已为您推荐同类题！
            </div>
            <div
              v-if="item.answers && item.answers.questionType == 1"
              class="similar"
            >
              <div class="answer-title">【同类题】</div>
              <el-button
                v-if="isInplan(item.answers.id, '1')"
                type="text"
                @click="setExampaperplanquestion(item.answers, true)"
              >
                加入试题篮
                <i class="el-icon-circle-plus-outline"> </i>
              </el-button>
              <el-button
                v-else
                type="text"
                @click="exampaperplanquestionDel(item.answers, true)"
              >
                移除试题篮<i class="el-icon-remove-outline"></i>
              </el-button>
            </div>
            <div
              v-if="item.answers && item.answers.questionType == 1"
              class="answer-content mb8"
            >
              <div v-html="item.answers.question"></div>
            </div>

            <template
              v-if="
                item.answers &&
                item.answers.answer &&
                item.answers.answer.length > 0
              "
            >
              <div class="answer-title mb8">【答案】</div>
              <div v-if="item.answers" class="answer-content mb8">
                <div v-html="item.answers.answer"></div>
              </div>
            </template>
            <template
              v-if="
                item.answers &&
                item.answers.analysis &&
                item.answers.analysis.length > 0
              "
            >
              <div class="answer-title mb8">【解答】</div>
              <div class="answer-content mb8">
                <div v-html="item.answers.analysis"></div>
              </div>
            </template>
            <template
              v-if="
                item.answers &&
                item.answers.analyse &&
                item.answers.analyse.length > 0
              "
            >
              <div class="answer-title mb8">【分析】</div>
              <div class="answer-content mb8">
                <div v-html="item.answers.analyse"></div>
              </div>
            </template>
            <template
              v-if="
                item.answers &&
                item.answers.ability &&
                item.answers.ability.length > 0
              "
            >
              <div class="answer-title mb8">【点评】</div>
              <div class="answer-content">
                <div v-html="item.answers.ability"></div>
              </div>
            </template>
          </div>
          <div v-if="item.showOpt" v-loading="item.loading" class="showOpt">
            <template v-if="item.opt && item.opt.length">
              <div v-for="(it, ind) in item.opt" :key="ind" class="opt-item">
                <div class="opt-header">
                  <div class="left">
                    <div class="questions-order">{{ it.questionOrder }}</div>
                    {{ displayDifficulty(it.difficulty) }}
                  </div>
                  <el-button
                    v-if="isInplan(it.id)"
                    type="text"
                    @click="setExampaperplanquestion(it, true)"
                  >
                    加入试题篮
                    <i class="el-icon-circle-plus-outline"> </i>
                  </el-button>
                  <el-button
                    v-else
                    type="text"
                    @click="exampaperplanquestionDel(it, true)"
                  >
                    移除试题篮<i class="el-icon-remove-outline"></i>
                  </el-button>
                </div>
                <div class="item-content mb18">
                  <div v-html="it.question"></div>
                </div>
                <div class="answer">
                  <span @click="optShowAnswer(it)">{{
                    it.showAnswer ? "收起答案" : "答案解析"
                  }}</span>
                </div>
                <template v-if="it.showAnswer">
                  <template v-if="it.answer && it.answer.length > 0">
                    <div class="answer-title mb8">【答案】</div>
                    <div class="answer-content mb8">
                      <div v-html="it.answer"></div>
                    </div>
                  </template>
                  <template v-if="it.analysis && it.analysis.length > 0">
                    <div class="answer-title mb8">【解答】</div>
                    <div class="answer-content mb8">
                      <div v-html="it.analysis"></div>
                    </div>
                  </template>
                  <template v-if="it.analyse && it.analyse.length > 0">
                    <div class="answer-title mb8">【分析】</div>
                    <div class="answer-content mb8">
                      <div v-html="it.analyse"></div>
                    </div>
                  </template>
                  <template v-if="it.ability && it.ability.length > 0">
                    <div class="answer-title mb8">【点评】</div>
                    <div class="answer-content">
                      <div v-html="it.ability"></div>
                    </div>
                  </template>
                </template>
              </div>
            </template>
            <div v-else class="tips">当前未找到相关同类题解析！</div>
          </div>
          <div v-if="item.showStat && item.options.length" class="progress">
            <progress-compoent
              :data="item.options"
              :class-num="params.classNum"
              :is-objective="item.questions[item.subIndex].isObjective"
              :score="item.questions[item.subIndex].score"
              :subject-id="subjectId"
              :right-answer="item.questions[item.subIndex].rightAnswer"
            />
            <div class="progress-text">
              *红线为年级占比，点击数据条显示对应学生名单
            </div>
          </div>
          <no-data v-if="item.showStat && !item.options.length" />
        </div>
      </div>
      <div v-else class="card" style="margin: 0; flex: 1">
        <no-data></no-data>
      </div>
    </div>

    <testBasket
      v-show="showBtn"
      :key="testBasketKey + subjectId"
      ref="testBasket"
      :plan-id="planId"
      @setQuestionList="setQuestionList"
      @setPlanid="setPlanid"
    ></testBasket>
  </div>
</template>
<script>
import {
  getprreportanalysis,
  getAnswer,
  examquestionOpt,
} from "@/core/api/academic/paperReport";
import { getStore } from "@/core/util/store";
import { Uniongetprreportanalysis } from "@/core/api/academic/unionReport";
import {
  exampaperplanquestionDel,
  exampaperplanquestion,
} from "@/core/api/resource/knowledgePointsPaper";
import progressCompoent from "./progress.vue";
import testBasket from "../../../resource/knowledgePointsPaper/components/testBasket.vue";
export default {
  name: "Analysis",
  components: {
    progressCompoent,
    testBasket,
  },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
    subjectId: {
      type: [String, Number],
      default: "",
    },
    isSchoolDisplayReportUnionAverageScore: {
      type: [String, Number, null],
      default: null,
    },
  },
  data() {
    let routerListStringfy = getStore({ name: "router-list" });
    let routerList = JSON.parse(routerListStringfy);
    let showBtn = false;
    if (routerList.length) {
      showBtn = routerList.some((item) => item.type === "analy");
    }
    return {
      knowledgeShow: false,
      showBtn: showBtn,
      statId: this.$route.query.id,
      loading: false,
      questionList: [],
      questionsWidth: 288,
      type: 0,
      subindex: 0,
      options: [
        {
          label: "重点讲评题目排序",
          value: 0,
        },
        {
          label: "按题号排序",
          value: 1,
        },
        {
          label: "按得分率排序",
          value: 2,
        },
      ],
      open: true,
      customColors: [
        { color: "#F24C4B", bgc: "#F24C4B", percentage: 20, init: 0 },
        { color: "#F1A53A", bgc: "#F1A53A", percentage: 40, init: 20 },
        { color: "#B9ACFB", bgc: "#B9ACFB", percentage: 60, init: 40 },
        { color: "#63A8EC", bgc: "#63A8EC", percentage: 80, init: 60 },
        { color: "#22C343", bgc: "#22C343", percentage: 100, init: 80 },
      ],
      planQuestionList: [],
      planId: 0,
      testBasketKey: 1,
      questionsNumList: [],
      clickQuestionrOder: null, // 点击左边题号面板时储存题号
      domOffsetTop: 0,
      targetToIndex: 0,
      oldQuestionList: [],
      oldQuestionsNumList: [],
      ratio: 1,
      originData: [],
      answerImgRatio: 1,
    };
  },

  mounted() {
    this.getprreportanalysisData();
  },
  destroyed() {
    window.removeEventListener("scroll", this.onscroll);
  },
  methods: {
    isOver(question) {
      const reg = /(height:(\d+)(px))/g;
      const heights = question.match(reg) || [];
      const arr = heights.map((item) => item.replace(/[^\d]/g, ""));
      const nums = arr.map((item) => Number(item));
      let total = eval(nums.join("+"));

      if (total * this.ratio < 160) {
        return "none";
      } else {
        return "block";
      }
    },
    blockClick(item) {
      if (!item.hidden) {
        this.$set(item, "hidden", true);
        this.filterData();
      } else {
        this.$set(item, "hidden", false);
        this.filterData();
      }
    },
    filterData() {
      const arr = this.customColors.filter((item) => !item.hidden);

      this.questionsNumList = this.oldQuestionsNumList.filter((item) => {
        const result = arr.some((v) => {
          if (v.percentage >= item.scoreRate && v.init <= item.scoreRate) {
            return true;
          }
          return false;
        });
        if (result) {
          return item;
        }
      });
      const data = this.oldQuestionList.map((item) => {
        let questions = item.questions.filter((it) => {
          const result = arr.some((v) => {
            if (v.percentage >= it.scoreRate && v.init <= it.scoreRate) {
              return true;
            }
            return false;
          });
          if (result) {
            return it;
          }
        });
        return {
          ...item,
          subIndex: 0,
          questions: questions,
        };
      });
      this.questionList = data.filter((item) => item.questions.length);
      this.selectChange();
      // this.initImg();
    },
    initAnswerImg() {
      var jzjxAnswerImg = document.getElementsByClassName("jzjxAnswerImg");
      var jzjxImgArr = [...Array.from(jzjxAnswerImg)];
      let maxWidth = 860;
      if (this.answerImgRatio === 1) {
        if (jzjxImgArr.length) {
          if (jzjxImgArr[0].style.maxWidth) {
            maxWidth = this.removePxToNumber(jzjxImgArr[0].style.maxWidth);
          }
          let width = this.removePxToNumber(jzjxImgArr[0].style.width);
          if (maxWidth < width) {
            maxWidth = width;
          }
          this.answerImgRatio = 860 / maxWidth;
        }
      }
      jzjxImgArr.forEach((item) => {
        this.$nextTick(() => {
          let styles = window.getComputedStyle(item);
          if (styles.display !== "block") {
            const width = this.removePxToNumber(item.style.width);
            const height = this.removePxToNumber(item.style.height);
            item.style.width = width * this.answerImgRatio + "px";
            item.style.height = height * this.answerImgRatio + "px";
            item.style.display = "block";
          }
        });
      });
    },
    initImg() {
      this.$nextTick(() => {
        if (this.ratio == 1) {
          var jzjxImg = document.getElementsByClassName("jzjxImg");
          let jzjxImgArr = [...Array.from(jzjxImg)];
          let maxWidth = 860;
          if (jzjxImgArr.length) {
            if (jzjxImgArr[0].style.maxWidth) {
              maxWidth = this.removePxToNumber(jzjxImgArr[0].style.maxWidth);
            }
            let width = this.removePxToNumber(jzjxImgArr[0].style.width);
            if (maxWidth < width) {
              maxWidth = width;
            }
            this.ratio = 860 / maxWidth;
          }
          jzjxImgArr.forEach((item) => {
            const width = this.removePxToNumber(item.style.width);
            item.style.width = width * this.ratio + "px";
            item.style.display = "block";
          });
        }
      });
    },
    isInplan(id) {
      let result = this.planQuestionList.indexOf(id) == -1;
      return result;
    },
    isInplanMaterial(item) {
      const id = item.questions[item.subIndex].questionIds[0];
      const result = this.planQuestionList.includes(id);
      return !result;
    },
    showStat(item, val) {
      let options = item.questions[item.subIndex].options;
      if (!item.showStat || val) {
        this.$set(item, "options", options);
        this.clearItemShow(item);
        this.$set(item, "showStat", true);
      } else {
        this.$set(item, "showStat", false);
      }
    },
    handleSubClick(item, index, v, n) {
      this.$set(item, "subIndex", n);
      this.subindex = this.questionsNumList.findIndex(
        (it) => it.questionOrder === v.questionOrder
      );
      if (item.showAnswer) {
        this.showAnswer(item, true);
      } else if (item.showOpt) {
        this.showOpt(item, true);
      } else if (item.showStat) {
        this.showStat(item, true);
      }
    },
    // 移除试题篮
    exampaperplanquestionDel(item, val = false) {
      let questionId = val
        ? item.id
        : item.questions[item.subIndex].questionIds[0];
      let data = {
        examPaperPlanId: this.planId,
        questionId: questionId,
      };
      exampaperplanquestionDel(data).then(() => {
        this.$refs.testBasket.getQuestion();
        this.$message({
          message: "移除成功！",
          type: "success",
        });
      });
    },
    setExampaperplanquestion(item, val = false) {
      // val 为true 时 传入item 为 类似同类题 类型
      let questionId = val
        ? item.id
        : item.questions[item.subIndex].questionIds[0];
      let data = {
        planId: this.planId,
        questionId: questionId,
      };

      exampaperplanquestion(data).then(() => {
        this.testBasketKey = Math.random();
        this.$nextTick(() => {
          this.$refs.testBasket.getQuestion();
          this.$message({
            message: "加入成功",
            type: "success",
          });
        });
        // val.isPlan = 1;
      });
    },
    setPlanid(planId) {
      this.planId = planId;
    },
    setQuestionList(val) {
      this.planQuestionList = val;
    },
    optShowAnswer(item) {
      let questionId = item.id;
      if (!item.showAnswer) {
        this.clearItemShow(item);
        this.$set(item, "showAnswer", true);
        this.$set(item, "loading", true);
        getAnswer(questionId)
          .then((res) => {
            if (questionId !== res.data.data.id) {
              // 没有答案解析
              let data = {
                questionType: 1,
                ...res.data.data,
              };
              this.$set(item, "answers", data);
              this.$nextTick(() => {
                this.initAnswerImg();
              });
            } else {
              // 有答案解析
              let data = {
                questionType: 2,
                ...res.data.data,
              };
              this.$set(item, "answers", data);
              this.$nextTick(() => {
                this.initAnswerImg();
              });
            }
            this.$set(item, "loading", false);
          })
          .catch(() => {
            this.$set(item, "loading", false);
          });
      } else {
        this.$set(item, "showAnswer", false);
      }
    },
    displayDifficulty(difficulty) {
      let str = "容易";
      if (difficulty <= 1 && difficulty >= 0.9) {
        str = "容易";
      } else if (difficulty < 0.9 && difficulty >= 0.7) {
        str = "较易";
      } else if (difficulty < 0.7 && difficulty >= 0.5) {
        str = "一般";
      } else if (difficulty < 0.5 && difficulty >= 0.3) {
        str = "较难";
      } else if (difficulty < 0.3 && difficulty >= 0) {
        str = "困难";
      }
      return str;
    },
    showOpt(item, val) {
      if (!item.showOpt || val) {
        let questionOrder = item.questions[item.subIndex].questionOrder;
        let examPaperId = item.questions[item.subIndex].examPaperId;
        let questionId = item.questions[item.subIndex].questionIds.length
          ? item.questions[item.subIndex].questionIds[0]
          : null;
        this.clearItemShow(item);

        this.$set(item, "showOpt", true);
        this.$set(item, "loading", true);
        examquestionOpt({
          questionOrder: questionOrder,
          examPaperId: examPaperId,
          questionId: questionId,
        })
          .then((res) => {
            this.$set(item, "opt", res.data.data);
            this.$set(item, "loading", false);
          })
          .catch(() => {
            this.$set(item, "loading", false);
          });
      } else {
        this.$set(item, "showOpt", false);
      }
    },
    clearItemShow(item) {
      this.$delete(item, "showAnswer");
      this.$delete(item, "showOpt");
      this.$delete(item, "showStat");
    },
    showAnswer(item, val) {
      let questionId = item.questions[item.subIndex].questionIds[0];
      if (!item.showAnswer || val) {
        this.clearItemShow(item);
        this.$set(item, "showAnswer", true);
        this.$set(item, "loading", true);
        getAnswer(questionId)
          .then((res) => {
            if (questionId !== res.data.data.id) {
              // 没有答案解析
              let data = {
                questionType: 1,
                ...res.data.data,
              };
              this.$set(item, "answers", data);
              this.$nextTick(() => {
                this.initAnswerImg();
              });
            } else {
              // 有答案解析
              let data = {
                questionType: 2,
                ...res.data.data,
              };
              this.$set(item, "answers", data);
              this.$nextTick(() => {
                this.initAnswerImg();
              });
            }
            this.$set(item, "loading", false);
          })
          .catch(() => {
            this.$set(item, "loading", false);
          });
      } else {
        this.$set(item, "showAnswer", false);
      }
    },

    onscroll() {
      let ws = document.documentElement.scrollTop || document.body.scrollTop;
      let questionOrder = this.clickQuestionrOder;
      this.questionList.forEach((item, index) => {
        if (!this.clickQuestionrOder) {
          questionOrder = item.questions[0].questionOrder;
        }
        let anchor = document.getElementById("question" + index);
        let subindex = this.questionsNumList.findIndex(
          (it) => it.questionOrder === questionOrder
        );

        if (anchor) {
          if (this.targetToIndex === subindex) {
            if (ws >= anchor.offsetTop - 180) {
              this.subindex = this.targetToIndex;
              setTimeout(() => {
                this.clickQuestionrOder = null;
              }, 10000);
            }
          } else {
            if (ws >= anchor.offsetTop - 180) {
              this.subindex = subindex;
            }
          }
        }
      });
    },
    extendItem(it) {
      this.$set(it, "open", true);
    },
    stowItem(it) {
      this.$set(it, "open", false);
    },
    closeBoard() {
      this.open = false;
      this.questionsWidth = 38;
    },
    openboard() {
      this.open = true;
      this.questionsWidth = 288;
    },
    targetTo(item, index) {
      this.clickQuestionrOder = item.questionOrder;
      const getIndex = this.questionList.findIndex((v) =>
        v.questions.some((it) => it.questionOrder === this.clickQuestionrOder)
      );
      let dom = document.getElementById("question" + getIndex);
      let itemSubIndex = this.questionList[getIndex].questions.findIndex(
        (it) => it.questionOrder === this.clickQuestionrOder
      );
      this.handleSubClick(
        this.questionList[getIndex],
        getIndex,
        this.questionList[getIndex].questions[itemSubIndex],
        itemSubIndex
      );
      this.domOffsetTop = dom.offsetTop - 80;
      this.targetToIndex = index;
      window.scrollTo({
        top: dom.offsetTop - 80,
        behavior: "smooth",
      });
    },

    getBtnLong(item) {
      if (item.questionOrder.length > 5) {
        return "long-btn";
      } else if (item.questionOrder.length > 2) {
        return "normal-btn";
      } else {
        return "btn";
      }
    },
    getBackgroundColor(item) {
      if (item.scoreRate < 20) {
        return "#F24C4B";
      } else if (item.scoreRate < 40 && item.scoreRate >= 20) {
        return "#F1A53A ";
      } else if (item.scoreRate < 60 && item.scoreRate >= 40) {
        return "#B9ACFB";
      } else if (item.scoreRate < 80 && item.scoreRate >= 60) {
        return "#63A8EC";
      } else if (item.scoreRate <= 100 && item.scoreRate >= 80) {
        return "#22C343";
      }
    },
    getColor(item) {
      if (item) {
        if (item.scoreRate < 20) {
          return "#F24C4B";
        } else if (item.scoreRate < 40 && item.scoreRate >= 20) {
          return "#F1A53A ";
        } else if (item.scoreRate < 60 && item.scoreRate >= 40) {
          return "#B9ACFB";
        } else if (item.scoreRate < 80 && item.scoreRate >= 60) {
          return "#63A8EC";
        } else if (item.scoreRate <= 100 && item.scoreRate >= 80) {
          return "#22C343";
        }
      }
    },
    getBorderColorClass(item) {
      if (item.scoreRate < 20) {
        return "border20";
      } else if (item.scoreRate < 40 && item.scoreRate >= 20) {
        return "border40";
      } else if (item.scoreRate < 60 && item.scoreRate >= 40) {
        return "border60";
      } else if (item.scoreRate < 80 && item.scoreRate >= 60) {
        return "border80";
      } else if (item.scoreRate <= 100 && item.scoreRate >= 80) {
        return "border100";
      }
    },
    async getprreportanalysisData() {
      this.loading = true;
      let res;
      if (this.$route.query.examType == 1) {
        res = await Uniongetprreportanalysis({
          id: this.statId,
          viewSchoolId: this.$route.query.schoolId,
          ...this.params,
        });
      } else {
        res = await getprreportanalysis({
          id: this.statId,
          ...this.params,
        });
      }

      this.ratio = 1;
      this.loading = false;
      this.oldQuestionList = res.data.data.map((item) => {
        return {
          subIndex: 0,
          ...item,
        };
      });

      this.knowledgeShow = res.data.data.every((item) =>
        item.questions.every((item) => item.knowledgeNames.length)
      );

      this.oldQuestionsNumList = res.data.data.flatMap(
        (item) => item.questions
      );
      this.filterData();
      window.addEventListener("scroll", this.onscroll);
    },

    removePxToNumber(val) {
      let data = val.replace("px", "");
      return Number(data);
    },
    selectChange() {
      // this.ratio = 1;
      if (this.type === 0) {
        this.questionList = this.questionsNumList.sort((a, b) => {
          return b.latestDevelopmentTimes - a.latestDevelopmentTimes;
        });
        this.questionList = this.oldQuestionList.sort((a, b) => {
          let aTotal = a.questions.reduce((atotal, item) => {
            atotal = atotal + item.latestDevelopmentTimes;
            return atotal;
          }, 0);
          let aAvg = aTotal / a.questions.length;
          let bTotal = b.questions.reduce((bTotal, item) => {
            bTotal = bTotal + item.latestDevelopmentTimes;
            return bTotal;
          }, 0);
          let bAvg = bTotal / b.questions.length;
          return bAvg - aAvg;
        });
      } else if (this.type === 1) {
        let isObjectiveArr = this.questionsNumList.filter(
          (item) => item.isObjective === 0
        );
        let notObjectiveArr = this.questionsNumList.filter(
          (item) => item.isObjective !== 0
        );
        notObjectiveArr = notObjectiveArr.sort(
          (a, b) => parseInt(a.questionOrder) - parseInt(b.questionOrder)
        );
        isObjectiveArr = isObjectiveArr.sort(
          (a, b) => parseInt(a.questionOrder) - parseInt(b.questionOrder)
        );
        this.questionsNumList = [...notObjectiveArr, ...isObjectiveArr];
        // this.questionList = [...this.questionsNumList];
        this.questionList = this.oldQuestionList.sort((a, b) => {
          return a.questionOrder - b.questionOrder;
        });
        this.initImg();
      } else if (this.type === 2) {
        this.questionsNumList = this.questionsNumList.sort(
          (a, b) => a.scoreRate - b.scoreRate
        );
        this.questionList = this.oldQuestionList.sort((a, b) => {
          let aTotal = a.questions.reduce((atotal, item) => {
            atotal = atotal + item.scoreRate;
            return atotal;
          }, 0);
          let aAvg = aTotal / a.questions.length;
          let bTotal = b.questions.reduce((bTotal, item) => {
            bTotal = bTotal + item.scoreRate;
            return bTotal;
          }, 0);
          let bAvg = bTotal / b.questions.length;
          return aAvg - bAvg;
        });
      }
      this.$nextTick(() => {
        if (this.questionsNumList.length) {
          this.targetTo(this.questionsNumList[0], 0);
        }
        this.initImg();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.section-title {
  font-size: 18px;
  color: #101011;
  width: fit-content;
  margin: 0 auto;
  position: relative;
  font-weight: 550;
  &::before {
    content: "";
    width: 64px;
    height: 2px;
    background-color: #f2f2f2;
    position: absolute;
    left: -72px;
    top: 50%;
    transform: translateY(-50%);
  }
  &::after {
    content: "";
    width: 64px;
    height: 2px;
    background-color: #f2f2f2;
    position: absolute;
    right: -72px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.questions-box {
  display: flex;
  align-items: flex-start;
  .questions {
    &-item {
      margin-bottom: 18px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0px 0px 5px 0px rgba(231, 231, 231, 0.7);
      .header {
        box-sizing: border-box;
        padding: 13px 18px;
        // border-bottom: 1px solid #e6e6e6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          .material {
            width: 32px;
            height: 32px;
            background: #63a8ec;
            border-radius: 4px;
            text-align: center;
            line-height: 32px;
            img {
              width: 16px;
              height: 16px;
            }
          }
          .questionNum {
            color: #fff;
            font-size: 14px;
            box-sizing: border-box;
            padding: 0 5px;
            height: 32px;
            min-width: 32px;
            text-align: center;
            line-height: 32px;
            border-radius: 4px;
            display: inline-block;
          }
          .questionType {
            font-size: 14px;
            color: #333333;
            padding-left: 24px;
          }
        }
        .right {
          font-size: 14px;
          color: #333333;
          .answer {
            padding-right: 24px;
            // border-right: 1px solid #e6e6e6;
            color: #3f87f4;
          }
          .fullscore {
            padding-left: 24px;
            color: #3f87f4;
          }
        }
      }
      .content {
        box-sizing: border-box;
        padding: 18px;
        padding-bottom: 0;
        .over {
          height: 160px;
        }
        &-question-close {
          border: 1px solid #f3f4f4;
          box-sizing: border-box;
          padding: 5px;
          margin-bottom: 10px;
          position: relative;
          ::v-deep img {
            height: auto !important;
            object-fit: contain;
          }
          ::v-deep .jzjxImg {
            max-width: 100% !important;
          }
          overflow: hidden;
          .open-btn {
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            height: 28px;
            text-align: center;
            color: #3f87f4;
            font-size: 14px;
            line-height: 28px;
            background: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(4px);
            cursor: pointer;
            i {
              font-size: 14px;
              margin-right: 4px;
            }
          }
        }
        &-question {
          border: 1px solid #f3f4f4;
          box-sizing: border-box;
          padding: 5px;
          margin-bottom: 10px;
          height: auto !important;
          ::v-deep img {
            height: auto !important;
            object-fit: contain;
            // width: auto !important;
            margin: 0 !important;
          }
          ::v-deep .jzjxImg {
            max-width: 100% !important;
            margin: 0 !important;
            // width: auto !important;
          }
        }
        .close-btn {
          width: 100%;
          text-align: center;
          color: #3f87f4;
          font-size: 14px;
          margin: 12px 0;
          cursor: pointer;
          i {
            font-size: 14px;
            margin-right: 4px;
          }
        }

        .no-data {
          width: 100%;
          height: auto;
          box-sizing: border-box;
          padding: 10px;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      .subQuestions {
        box-sizing: border-box;
        padding: 18px 18px 0 18px;
        .subQuestions-tabs {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          .subQuestions-tab {
            min-width: 32px;
            text-align: center;
            box-sizing: border-box;
            padding: 0 5px;
            height: 32px;
            border-radius: 4px;
            margin-right: 8px;
            line-height: 32px;
            border: 1px solid;
            margin-bottom: 18px;
            cursor: pointer;
          }
        }
      }
      .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0px 18px 18px 18px;
        .footer-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 8px;
          background-color: #f8f8fa;
          width: 100%;
          .scoreStat {
            font-size: 12px;
            color: #333333;
          }
          .btns {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
      .knowledgeNames {
        font-size: 14px;
        box-sizing: border-box;
        padding: 0 18px 10px 18px;
        display: flex;
        align-items: flex-start;
        margin-bottom: 8px;
        .item-label {
          line-height: 28px;
        }
        .knowledgeNames-content {
          flex-wrap: wrap;
          display: flex;
          align-items: flex-start;
          .knowledgeNames-item {
            box-sizing: border-box;
            padding: 0 8px;
            height: 28px;
            line-height: 28px;
            font-size: 12px;
            background: #f3f5ff;
            color: #3f87f4;
            margin-right: 5px;
            margin-bottom: 5px;
          }
        }
      }
      .showAnswer {
        box-sizing: border-box;
        padding: 18px;

        .similar {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .answer-title {
          color: #3f87f4;
          font-size: 14px;
        }
        .mb8 {
          margin-bottom: 8px;
        }
      }
      .showOpt {
        box-sizing: border-box;
        padding: 18px;
        .mb18 {
          margin-bottom: 18px;
        }
        .opt-item {
          .opt-header {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            justify-content: space-between;
            .left {
              display: flex;
              align-items: center;
              color: #101011;
              font-size: 14px;
              .questions-order {
                box-sizing: border-box;
                padding: 4px 10px;
                background-color: #3f87f4;
                color: #fff;
                margin-right: 8px;
              }
            }
          }
          .item-content {
            text-align: left;
          }
          .answer {
            box-sizing: border-box;
            padding: 8px 18px;
            font-size: 14px;
            color: #3f87f4;
            background: #f8f8fa;
            text-align: right;
            span {
              cursor: pointer;
            }
          }
          .answer-title {
            color: #3f87f4;
            font-size: 14px;
          }
          .mb8 {
            margin-bottom: 8px;
          }
        }
      }
      .progress {
        box-sizing: border-box;
        padding-bottom: 20px;
        ::v-deep .progress-item {
          margin-bottom: 24px;
        }
        &-text {
          text-align: center;
          font-size: 14px;
          color: #8c8c8c;
        }
      }
    }
  }
  .questions-board-close {
    width: 38px;
    position: -webkit-sticky;
    position: sticky;
    top: 72px;
    left: 0;
    margin-right: 20px;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 5%);
    border-radius: 4px;
    background-color: #3f87f4;
    color: #fff;
    cursor: pointer;
    box-sizing: border-box;
    padding: 12px;
  }
  .questions-board {
    width: 288px;
    position: -webkit-sticky;
    position: sticky;
    top: 72px;
    left: 0;
    margin-right: 20px;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 5%);
    border-radius: 4px;
    background-color: #fff;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 13px 18px;
      border-bottom: 1px solid #e6e6e6;
      font-size: 14px;
      color: #333333;
      ::v-deep .el-input__inner {
        height: 28px;
        line-height: 28px;
      }
      .close {
        display: flex;
        align-items: center;
        .el-icon-arrow-left::before {
          font-size: 12px;
          line-height: 12px;
        }
        color: #3f87f4;
        font-size: 14px;
        box-sizing: border-box;
        padding: 4px;
        background: #f3f5ff;
        cursor: pointer;
      }
    }
    .content {
      box-sizing: border-box;
      padding-bottom: 18px;
      .colorList {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding: 0 18px;
        &-item {
          display: flex;
          align-items: center;
          margin-bottom: 14px;
          width: 100%;
          font-size: 14px;
          .block {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            cursor: pointer;
          }
        }
      }
      .btn-box {
        overflow-y: auto;
        box-sizing: border-box;
        padding: 18px 18px 0 18px;
        max-height: 400px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        grid-gap: 8px;
        margin-bottom: 10px;
        &::-webkit-scrollbar {
          width: 10px;
          height: 3px;
        }

        ///*<!--修改 滑块 -->*/
        // border: 1px solid #DEE1E7;
        &::-webkit-scrollbar-thumb {
          background-color: #dee1e7;
        }
      }
      .tip {
        color: #aaaaaf;
        font-size: 14px;
        line-height: 19px;
        box-sizing: border-box;
        padding: 0 12px;
        text-align: center;
      }
    }
  }
}
::v-deep .el-dialog {
  box-shadow: 0 1px 3px rgb(0 0 0 / 1%);
}
.border20 {
  &:hover {
    border-color: #fc8585 !important;
  }
}
.border40 {
  &:hover {
    border-color: #fcc185 !important;
  }
}
.border60 {
  &:hover {
    border-color: #b9acfb !important;
  }
}
.border80 {
  &:hover {
    border-color: #63a8ec !important;
  }
}
.border100 {
  &:hover {
    border-color: #6edaa6 !important;
  }
}
.tips {
  color: #666160;
  font-size: 14px;
  box-sizing: border-box;
  padding: 4px 10px;
  background-color: #fff5f2;
  border: 1px dashed #ffb59f;
  margin-bottom: 18px;
}
.btn {
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  height: 32px;
  font-size: 12px;
  line-height: 32px;
  width: 32px;
  border-radius: 4px;
  border: 1px solid;
}
.normal-btn {
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  height: 32px;
  font-size: 12px;
  line-height: 32px;
  width: 72px;
  border-radius: 4px;
  border: 1px solid;
}
.long-btn {
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  height: 32px;
  font-size: 12px;
  line-height: 32px;
  border-radius: 2px;
  border: 1px solid;
  width: 112px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
::v-deep p {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
::v-deep .jzjxAnswerImg {
  will-change: width;
}
.answer-content {
  overflow: hidden;
}
</style>
